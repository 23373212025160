import React, { useState, useEffect } from "react";
import { MDBCard, MDBCardImage, MDBCardTitle, MDBCardFooter, MDBCardBody } from "mdb-react-ui-kit";

import { Row, Col, Modal, Button } from "react-bootstrap";
import CardCart from "./CardCart";

function ProductsCard() {
    console.log('products');
    const [products, setProducts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        fetch("https://team04mapi.webpark.tech/api/products", { method: "GET", mode: "cors" })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setProducts(data.data);
                console.log(data);
            })
            .catch((error) => console.error(error));
    }, []);

    const userId = localStorage.getItem('id');

    const handleCardClick = (product) => {
        setSelectedProduct(product);
        setShowModal(true);
    };

    return (
        <div>

            <Row>
                {products.map(({ id, avatar, productName, description, price }) => (
                    <Col md={3} xs={12} className="mb-5 d-flex justify-content-center" key={id}>
                        <MDBCard className="alignment-center" style={{ minWidth: "250px", maxWidth: "390px", maxHeight: "400px" }}>
                            <MDBCardImage style={{ height: "200px", width: "100%", objectFit: "cover" }} src={`https://team04mapi.webpark.tech/Images/${avatar}`} alt="..." position="top" onClick={() => handleCardClick({ id, avatar, productName, description, price })} />
                            <MDBCardBody>
                                <MDBCardTitle className="text-truncate" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{productName}</MDBCardTitle>
                                <p onClick={() => handleCardClick({ id, avatar, productName, description, price })} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{description}</p>
                                <h6 className="mt-1">₱{price}</h6>
                            </MDBCardBody>
                            <MDBCardFooter className="d-flex justify-content-center align-items-center">
                                <CardCart product_id={id} user_id={userId} />
                            </MDBCardFooter>
                        </MDBCard>
                    </Col>
                ))}
            </Row>


            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedProduct && selectedProduct.productName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedProduct && (
                        <div style={{ textAlign: "center" }}>
                            <img
                                src={`https://team04mapi.webpark.tech/Images/${selectedProduct.avatar}`}
                                alt="Product"
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "200px",
                                    objectFit: "contain",
                                }}
                            />
                            <p>{selectedProduct.description}</p>
                            <h6>₱{selectedProduct.price}</h6>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ProductsCard;
