import React, { useState } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
  MDBCol,
} from "mdb-react-ui-kit";
import { BsFillCartFill, BsFillPersonFill } from "react-icons/bs";
import { Button, Image, Nav } from "react-bootstrap";
import "../Styles/Homepagestyle/Header.css";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../Images/Logo.png";

export default function App() {
  const [showBasic, setShowBasic] = useState(false);
  const [showNext, setShowNext] = useState(false);

  const token = localStorage.getItem("token");
  const navigate = useNavigate();


  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userType");
    localStorage.removeItem("id")
    navigate('/');
    window.location.reload();
  }

  return (
    <>
      <MDBNavbar expand="lg" sticky="top" className="navbarColor">
        <MDBContainer fluid>
          {/* LOGO */}
          <NavLink to={"/Home"}>
            <img className="me-5" src={logo} />
          </NavLink>

          {/* END LOGO */}

          {/* Toggler for Phone */}
          <MDBNavbarToggler
            style={{ color: "white" }}
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowBasic(!showBasic)}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>
          {/* Collapse toggle for phone */}
          <MDBCollapse navbar show={showBasic}>
            {/* NAV BAR */}
            <MDBCol lg={7}>
              <MDBNavbarNav className="d-flex justify-content-start  ms-auto mr-auto mb-2 mb-lg-0 ">
                {/* HOME */}

                {/* <div className="d-flex align-content-start justify-content-start"> */}
                <MDBNavbarItem className="mt-4 ms-3">
                  <NavLink to={"/home"}>
                    <h5 className="p navbarLink">Home</h5>
                  </NavLink>

                </MDBNavbarItem>


                {/* END HOME */}

                {/* BRAND */}

                {/* <NavLink to={"/AdminProducts"}>
                  <MDBNavbarItem className="mt-4 ms-3">
                    <h5 className="navbarLink">Brands</h5>
                  </MDBNavbarItem>
                </NavLink> */}

                {/* END BRAND */}

                {/* Products */}

                <MDBNavbarItem className="mt-4 ms-3">

                  <NavLink to={"/Products"}>
                    <h5 className="navbarLink">Products</h5>
                  </NavLink>
                </MDBNavbarItem>
                {/* </div> */}
              </MDBNavbarNav>
            </MDBCol>

            {/* End Collapse toggle for phone */}

            {/* FORM AND BUTTON TOGGLE */}

            {/* Collapse toggle for phone */}

            <MDBCol>
              <MDBNavbarNav className="d-flex justify-content-end  mt-2 mr-auto mb-2 mb-lg-0 ">
                {/* SearchBar */}

                {/* <MDBNavbarItem>
                  <form className="d-flex input-group w-auto mb-2 mt-2 formStyle">
                    <input type="search" className="form-control formStyle" placeholder="Type here" aria-label="Search" />
                    <MDBBtn color="warning">Search</MDBBtn>
                  </form>
                </MDBNavbarItem> */}

                {/* SearchBar End */}

                {/* LOG IN */}

                <MDBNavbarItem className="mt-2 mb-1 ms-2 headerNavButton">

                  {token ?
                    <div>
                      {/* <NavLink to={'/User'}> */}
                      {/* <Button className="btn btn-warning me-2">Profile</Button> */}
                      {/* <h5 className="me-2 mt-3 ms-3 navbarLink">Profile</h5> */}
                      {/* </NavLink> */}
                      <NavLink to={'/Home'}>
                        {/* <Button className="btn btn-warning" onClick={logout}>Logout</Button> */}
                        <h5 className="navbarLink me-2 mt-3 ms-3" onClick={logout}>Log Out</h5>
                      </NavLink>
                    </div>

                    :
                    // <a onClick={logout} ></a>
                    <NavLink to={'/Login'}>
                      {/* <Button className="btn btn-warning">Login</Button> */}
                      <h5 className="mt-3 me-2 ms-3 navbarLink"> Login</h5>
                    </NavLink>

                  }

                </MDBNavbarItem>
                {/* <MDBNavbarItem className="mt-2 mb-1 ms-3 headerNavButton">
                    <Button className="buttonClass btn btn-warning">
                      <BsFillPersonFill />
                    </Button>
                  </MDBNavbarItem>
                </NavLink> */}

                {/* LOG IN END */}

                {/* CART */}

                <NavLink to={"/Cart"}>
                  <MDBNavbarItem className="mt-4 me-5 mb-1 ">
                    {/* <Button className="btn btn-warning"> */}
                    <BsFillCartFill className="navbarLink" style={{ width: "50px" }} />
                    {/* </Button> */}
                  </MDBNavbarItem>
                </NavLink>

                {/* CART END */}
              </MDBNavbarNav>
            </MDBCol>
          </MDBCollapse>
          {/* End Collapse toggle for phone */}
        </MDBContainer>
      </MDBNavbar >
    </>
  );
}
