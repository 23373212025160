import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import logo from "../Images/jrcaboutus.png"
import Fade from 'react-awesome-reveal'
import "../Styles/Homepagestyle/aboutUsPage.css"
import Wave from 'react-wavify'

function Aboutus() {
  const style = {
    color: { color: "white" },
  };

  return (
    <div className='aboutUsStyle'>
      <Container>

        <Wave fill='#f79902'
          paused={false}
          options={{
            height: 60,
            amplitude: 80,
            speed: .20,
            points: 2
          }}></Wave>

        <Row>
          <Col>

            <Image src={logo} md={6} fluid></Image>

          </Col>

          <Col className='mt-5 mb-5' md={6}>

            <Fade >
              <p style={style.color} >
                <h1 style={style.color}>Welcome to JRC Tech</h1>
                <br />
                Your Ultimate Destination for Computer Parts!
                <br />
                At JRC Tech, we are dedicated to providing you with a seamless and rewarding shopping experience for all your computer parts needs. As passionate tech enthusiasts ourselves, we understand the thrill of building and upgrading PCs to unlock their full potential.
                <br />
                <br />

                Our carefully curated selection boasts top-tier components from leading brands, ensuring unrivaled performance, reliability, and cutting-edge technology. Whether you're a hardcore gamer, a creative professional, or a tech-savvy enthusiast, we have the perfect components to cater to your specific requirements.</p>
            </Fade>
          </Col>

        </Row>

      </Container>

    </div>
  )
}

export default Aboutus