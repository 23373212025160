import { Row, Container, Col, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';

function EditForms(productId) {
    const [products, setProducts] = useState([]);
    const [id, setId] = useState('');
    const [productName, setProductName] = useState('');
    const [category, setCategory] = useState('');
    const [quantity, setQuantity] = useState('');
    const [avatar, setAvatar] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');

    useEffect(() => {
        setId(productId.productId);
    }, [productId]);

    // console.log(id)
    const putProducts = (products) => {
        console.log(productId);
        fetch(`https://team04mapi.webpark.tech/api/products/edit/${id}`, {
            method: 'PUT',
            body: products
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    const newTasks = [...products];
                    newTasks.push(data.data);
                    setProducts(newTasks);
                } else {
                    alert('Successfully added to files');
                    handleSubmit('')
                }
            })
            .catch(error => console.error(error));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!description) return;
        const data = new FormData(e.target);
        putProducts(data);
        setDescription('');
        setQuantity('');
        setProductName('');
        setCategory('');
        setPrice('');
    };

    return (
        <>
            <Container>
                <Form encType="multipart/form-data" onSubmit={handleSubmit}>
                    <br />
                    <p className='mb-1'>Product Name</p>
                    <Form.Control name="productName" type="text" placeholder="Normal text" value={productName} onChange={(e) => setProductName(e.target.value)} />
                    <br />
                    <p className='mb-1'>Category</p>
                    <Form.Control name="category" type="text" placeholder="Normal text" value={category} onChange={(e) => setCategory(e.target.value)} />
                    <br />
                    <p className='mb-1'>Quantity</p>
                    <Form.Control name="quantity" type="text" placeholder="Normal text" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                    <br />
                    <p className='mb-1'>Description</p>
                    <Form.Control name="description" type="text" placeholder="Normal text" value={description} onChange={(e) => setDescription(e.target.value)} />
                    <br />
                    <p className='mb-1'>Price</p>
                    <Form.Control name="price" type="text" placeholder="Normal text" value={price} onChange={(e) => setPrice(e.target.value)} />
                    <br />
                    <p className='mb-1'>Image</p>
                    <Form.Control name="avatar" type="file" placeholder="Normal text" value={avatar} onChange={(e) => setAvatar(e.target.value)} />
                    <br />
                    <div className='d-flex justify-content-end'>
                        <Button variant="success" as="input" type="submit" value="Submit" />{' '}
                    </div>
                </Form>
            </Container>
        </>
    );
}

export default EditForms;
