import React from "react";
import { MDBCard, MDBCardImage, MDBCardTitle, MDBCardFooter, MDBCardBody } from "mdb-react-ui-kit";
import { Fade } from "react-awesome-reveal";
import CardCart from "./CardCart";

function Card(props) {
  return (
    <div>
      <Fade>
        <MDBCard alignment="center" style={{ minWidth: "150px", maxWidth: "250px" }}>
          <MDBCardImage src={props.image} alt="..." position="top" />
          <MDBCardBody>
            <MDBCardTitle className="text-truncate">{props.title}</MDBCardTitle>
          </MDBCardBody>
        </MDBCard>
      </Fade>
    </div>
  );
}

export default Card;
