import React from "react";
import { MDBDataTable } from "mdbreact";
import "../../Style/Admin.css";
import { useState, useEffect } from "react";
import { Button, Image } from "react-bootstrap";
import EditIcon from "./EditIcon";
import DeleteIcon from "./DeleteIcon"

const DatatablePage = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // console.log(constants.ENDPOINT);
    fetch(`https://team04mapi.webpark.tech/api/products`, { method: "GET", mode: "cors" })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setProducts(data.data);
        console.log(data);
      })
      .catch((error) => console.error(error));

  }, []);

  // console.log();


  // TO ADD THE ARRAY OF OBJECT IN THE TABLE
  console.log(products)
  const productsObject = products.map(({ id, avatar, productName, category, quantity, description, price }) => {
    return {
      avatar: <Image style={{ width: '80px', objectFit: "contain" }} src={`https://team04mapi.webpark.tech/Images/${avatar}`} />,
      productName,
      category,
      quantity,
      description,
      price,
      // !to do add onclick
      edit: <EditIcon productId={id} className="editIcon" />,
      delete: <DeleteIcon productId={id} className="deleteIcon" />,
    };
  });

  const data = {
    columns: [
      {
        label: "avatar",
        field: "avatar",
        sort: "asc",
        width: 150,
      },
      {
        label: "productName",
        field: "productName",
        sort: "asc",
        width: 150,
      },

      {
        label: "description",
        field: "description",
        sort: "asc",
        width: 200,
      },
      {
        label: "category",
        field: "category",
        sort: "asc",
        width: 200,
      },
      {
        label: "quantity",
        field: "quantity",
        sort: "asc",
        width: 100,
      },
      {
        label: "price",
        field: "price",
        sort: "asc",
        width: 100,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 100,
      },
      {
        label: "Delete",
        field: "delete",
        sort: "asc",
        width: 100,
      },
    ],
    rows: productsObject,
  };

  return <MDBDataTable striped bordered small data={data} />;
};

export default DatatablePage;
