import React from 'react'
import Header from "./Header"
import { Image, Container, Row, Col, Button } from "react-bootstrap"
import checkout from "../Images/checkout.png"
import "../Styles/Homepagestyle/Checkout.css"
import { NavLink } from 'react-router-dom'

function Checkout() {
    return (
        <>
            <Header />
            <div className='checkOutStyle'>
                <br />
                <br />
                <Container >
                    <Row>
                        <Col md={6}>
                            <Image className="rounded" src={checkout} fluid />
                        </Col>
                        <Col md={6}>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <h4 className='mt-5 ms-4 mb-5'>Details of your order is sent to your email. <br /><br />Thank you for shopping!
                            </h4>
                            <div className='d-flex justify-content-start ms-5'>
                                <NavLink to={"/Products"}>
                                    <Button variant='warning'>Shop Again</Button>
                                </NavLink>
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                        </Col>
                    </Row>
                    <br />
                    <br />
                </Container >
            </div >
        </>

    )
}

export default Checkout