import HeroSection from "./HeroSection.js";
import Aboutus from "./Aboutus.js";
import Header from "./Header.js";
import ProductAndServices from "./ProductsAndServices.js";
import FAQ from "./FAQ.js";
import Category from "./Category.js";

function Home() {
  return (
    <div>
      <Header />
      <HeroSection />
      <Aboutus />
      <Category />
      <ProductAndServices />
      <FAQ />
    </div>
  );
}

export default Home;
