import React from "react";
import AdminProducts from "../Admin/Component/Pages/AdminProducts"

export default function Brands() {
  return (
    <div>
      <AdminProducts />
    </div>
  );
}
