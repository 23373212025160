import React, { useState } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import "./Style/Admin.css"


const style = {
  color: { color: "white" },
};

export default function App() {
  const [showBasic, setShowBasic] = useState(false);

  return (
    <MDBNavbar style={{ height: "100px" }} expand="lg" className="headerStyle">
      <MDBContainer fluid>
        <MDBNavbarBrand style={style.color} href="#">DASHBOARD</MDBNavbarBrand>

        <MDBNavbarToggler aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setShowBasic(!showBasic)}>
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler>

        <MDBCollapse navbar show={showBasic}>
          <MDBNavbarNav className="mr-auto mb-2 ms-5 mb-lg-0 d-flex justify-content-center">

            <MDBNavbarItem>
              <MDBNavbarLink style={style.color} href="#">Products</MDBNavbarLink>
            </MDBNavbarItem>


          </MDBNavbarNav>


        </MDBCollapse >
      </MDBContainer >
    </MDBNavbar >
  );
}
