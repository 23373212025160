import React from "react";
import { MDBRow, MDBCol, MDBContainer, MDBBtn } from "mdb-react-ui-kit";
import Card from "./Card";
import "../Styles/Homepagestyle/CategoryStyle.css";
import { Stack, Container, Button } from "react-bootstrap";
import motherboard from "../Images/motherboard.jpg"
import videocard from "../Images/videocard.jpg"
import processor from "../Images/processor.jpg"
import coolingfan from "../Images/coolingfan.jpg"
import ram from "../Images/ram.jpg"
import { NavLink } from "react-router-dom";

function Category() {
  return (
    <div className="categoryStyle">
      <MDBContainer>
        <MDBRow>
          <br />
          <br />
          <div className="d-flex justify-content-center mb-5 mt-5">
            <h1 style={{ color: "white" }}>Explore Popular Products</h1>
          </div>
        </MDBRow>

        {/* className="d-flex justify-content-center row-cols-1 row-cols-md-4 g-5" */}

        <MDBRow>
          <Container className="d-flex justify-content-center">
            <Stack direction="horizontal" gap={3} className="overflow-auto">
              <Card title="Motherboard" image={motherboard} />
              <Card title="Video Card" image={videocard} />
              <Card title="Cooling Fans" image={coolingfan} />
              <Card title="Processor" image={processor} />
              <Card title="Ram" image={ram} />
            </Stack>
          </Container>
        </MDBRow>
        <br />
        <div className="d-flex justify-content-center">
          <NavLink to={"/Products"}>
            <Button variant="warning">Shop now</Button>
          </NavLink>
        </div>
        <br />
      </MDBContainer>
    </div>
  );
}

export default Category;
