import React from "react";
import { Routes, Route } from "react-router";
import Home from "./Home";
import Products from "./Products";
import Brands from "./Brands";
import Cart from "./Cart";
import Login from "./Login";
import Checkout from "./Checkout";

import AdminProducts from "../Admin/Component/Pages/AdminProducts";

import User from "./User"
// import { Home, Login, Cart, Brands, Products } from "../Components";
// import { AdminVideocards, AdminProducts, AdminMotherboards, AdminRams, AdminSystemCoolingFans } from "../Component/Pages";

export default function () {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/User" element={<User />} />
        <Route path="/Brands" element={<Brands />} />
        <Route path="/Cart" element={<Cart />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Products" element={<Products />} />
        <Route path="/AdminProducts" element={<AdminProducts />} />
        <Route path="/Checkout" element={<Checkout />} />

      </Routes>
    </>
  );
}
