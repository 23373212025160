import React from "react";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import { Container } from "react-bootstrap";
import "../Styles/Homepagestyle/FAQsection.css";

export default function App() {
  return (
    <div className="FAQStyle">
      <Container>
        <br />
        <br />
        <h1 style={{ color: "white" }}>Help FAQ</h1>
        <br />
        <br />
        <MDBAccordion alwaysOpen initialActive={1}>
          <MDBAccordionItem collapseId={1} headerTitle="Q: What computer parts does JRC Tech offer?">
            <strong>
              <p>
                RC Tech offers a wide range of computer parts, including processors, motherboards, graphics cards, memory (RAM), storage drives (HDDs and SSDs), power supplies, cooling solutions, and more.{" "}
              </p>
            </strong>

          </MDBAccordionItem>
          <MDBAccordionItem collapseId={2} headerTitle="Q: Are JRC Tech's computer parts compatible with all computer brands?">
            <strong>
              <p>
                A: JRC Tech provides computer parts that are generally compatible with major computer brands. However, it's essential to check the specifications and compatibility details of each product to ensure they match your specific system requirements.
              </p>

            </strong>
          </MDBAccordionItem>
          <MDBAccordionItem collapseId={3} headerTitle="Q: Does JRC Tech offer warranty on their computer parts?">
            <strong>
              <p>
                A: Yes, all computer parts sold by JRC Tech come with a standard warranty. The duration of the warranty may vary depending on the product; customers should refer to the product listing or contact customer support for specific warranty details.{" "}
              </p>
            </strong>
          </MDBAccordionItem>
          <MDBAccordionItem collapseId={3} headerTitle="Q: How can I determine which processor is best for my needs?">
            <strong>
              <p>
                A: Choosing the right processor depends on your computing requirements, such as gaming, video editing, or office tasks. JRC Tech provides detailed specifications and performance benchmarks for each processor to help you make an informed decision. You can also reach out to their customer support for personalized recommendations.{" "}
              </p>
            </strong>
          </MDBAccordionItem>
          <MDBAccordionItem collapseId={3} headerTitle="Q: Can I upgrade my graphics card without replacing other components?">
            <strong>
              <p>
                A: In most cases, upgrading the graphics card is possible without replacing other components. However, it's crucial to ensure that your system's power supply and motherboard support the new graphics card's requirements. JRC Tech's customer support can assist you in finding a compatible upgrade.{" "}
              </p>
            </strong>
          </MDBAccordionItem>
        </MDBAccordion>
        <br />
        <br />
      </Container>
    </div>
  );
}
