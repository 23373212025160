import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import ImageHeroPage from "../Images/HeroImage.png";
import "../Styles/Homepagestyle/heroPageStyle.css";
import { Fade } from "react-awesome-reveal";
import { NavLink } from "react-router-dom";

export default function HeroSection() {
  return (
    <>

      <Container className="vh-100 heroPageStyle" fluid>
        <br />
        <Row>
          <Col lg={6} className="d-flex justify-content-center align-items-center">
            <Fade>
              <Container>
                <h2 className="ms-5" id="h2Hero" style={{ color: "white" }}>
                  Power Up Your Performance with Cutting-Edge Computer Parts!
                </h2>
                <p className="ms-5" id="h2Hero" style={{ color: "white" }}>
                  Upgrade Your Digital Frontier - JRC Tech: Where Cutting-Edge Components Meet Unparalleled Performance.
                </p>
                <NavLink to={'/Products'}>
                  <Button className="ms-5 btn btn-warning" id="h2Hero">
                    SHOP NOW
                  </Button>
                </NavLink>
              </Container>
            </Fade>
          </Col>
          <Col lg={5} className="d-flex justify-content-center align-items-center mt-5">
            <Fade direction="down">
              <img src={ImageHeroPage} className="img-fluid overflow-hidden heroObject" alt="" />
            </Fade>
          </Col>
        </Row>
      </Container>

    </>
  );
}
