import React from 'react'
import { BsFillCartFill } from "react-icons/bs";
import { Button, Image } from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.css';
import toast, { Toaster } from 'react-hot-toast';


function CardCart({ user_id, product_id }) {
    // const [showA, setShowA] = useState(true);
    // const toggleShowA = () => setShowA(!showA);


    const addCart = () => {


        const customer = localStorage.getItem('id')
        if (customer === "undefined" || customer === "" || customer === "null" || !customer) {
            toast.success('Successfully added to cart!')
            const guestCart = localStorage.getItem("cartguest")
            let cart = [];
            try {
                cart = JSON.parse(guestCart) || []
            } catch (error) {
                cart = []
            }
            const findProductById = product_id => cart.filter((p) => p.product_id === product_id);
            const findIndex = product => cart.indexOf(product);

            console.log(findProductById(product_id))
            console.log(cart.indexOf(findProductById(product_id)[0]))
            console.log(findIndex(findProductById(product_id)[0]))

            if (findProductById(product_id).length > 0) {
                cart[findIndex(findProductById(product_id)[0])].quantity += 1;
            } else {
                cart.push({ user_id, product_id, quantity: 1 })
            }
            localStorage.setItem("cartguest", JSON.stringify(cart))
        } else {
            fetch(`https://team04mapi.webpark.tech/api/cartusers`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ user_id, product_id, quantity: 1 }),
            })
                .then(() => { toast.success('Successfully added to cart!') })
                .catch((error) => console.error(error));
            console.log('addCart', user_id, product_id)
        }
    }



    return (
        <div>
            <Button variant="warning" onClick={addCart}>
                <BsFillCartFill />
            </Button>
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
        </div>
    )
}

export default CardCart