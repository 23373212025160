import React from 'react'
import { BsPencilSquare } from "react-icons/bs";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import EditForms from "../Forms/EditForms"

function EditIcon(productId) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // console.log(productId.productId)
    return (
        <>

            <BsPencilSquare onClick={handleShow} />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Products</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditForms productId={productId.productId} />


                </Modal.Body>
            </Modal>
        </>
    );
}


export default EditIcon