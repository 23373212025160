
import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import "../Styles/Homepagestyle/Footer.css"

export default function App() {
  const style = {
    color: { color: "white" },
  };
  return (
    <MDBFooter className='text-center text-lg-start text-muted footerStyle'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span style={style.color}>Get connected with us on social networks:</span>
        </div>

        <div>
          <a href='https://www.facebook.com/royce.tecson.399/' target='_blank' className='me-4 text-reset'>
            <MDBIcon style={style.color} fab icon="facebook-f" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon style={style.color} fab icon="twitter" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon style={style.color} fab icon="google" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon style={style.color} fab icon="instagram" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon style={style.color} fab icon="linkedin" />
          </a>
          <a href='https://github.com/RoyceGhub' target='_blank' className='me-4 text-reset'>
            <MDBIcon style={style.color} fab icon="github" />
          </a>
        </div>
      </section>

      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 style={style.color} className='text-uppercase fw-bold mb-4'>
                <MDBIcon icon="gem" className="me-3" />
                JRC Tech
              </h6>
              <p style={style.color}>
                Unlock Your PC's Potential with JRC Tech - Where Cutting-Edge Components Fuel Your Imagination
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 style={style.color} className='text-uppercase fw-bold mb-4'>Products</h6>
              <p style={style.color}>
                <a href='#!' className='text-reset'>
                  Motherboard
                </a>
              </p>
              <p style={style.color}>
                <a href='#!' className='text-reset' >
                  Videocard
                </a>
              </p>
              <p style={style.color}>
                <a href='#!' className='text-reset' >
                  Cooling Fan
                </a>
              </p>
              <p style={style.color}>
                <a href='#!' className='text-reset' >
                  Processor
                </a>
              </p>
            </MDBCol>



            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 style={style.color} className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p style={style.color}>
                <MDBIcon icon="home" className="me-2" />
                Makati, Legazpi street
              </p>
              <p style={style.color} >
                <MDBIcon icon="envelope" className="me-3" />
                roycetecson@gmail.com
              </p>
              <p style={style.color}>
                <MDBIcon icon="phone" className="me-3" /> +63 949-985-5179
              </p>
              <p style={style.color}>
                <MDBIcon icon="print" className="me-3" /> + 01 234 567 89
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4'><p style={style.color}>© 2023 Copyright:JRC Tech
      </p>
      </div>
    </MDBFooter >
  );
}