import React from 'react';
import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBInput
}
    from 'mdb-react-ui-kit';
import { useState } from 'react';
import Header from "./Header"
import { Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import "../Styles/Homepagestyle/login.css"
import logo from "../Images/Login.png"


function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();


    const handleLogin = async (e) => {
        e.preventDefault();

        const data = {
            email: email,
            password: password
        };

        fetch('https://team04mapi.webpark.tech/api/users/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => {
                // Assuming the server returns a token upon successful login
                const token = result.token;
                const userid = result.id;
                const usertype = result.userType;

                console.log(result)






                if (token == null) {
                    setError('Incorrect Email or Password')
                } else {
                    localStorage.setItem('token', token);
                    localStorage.setItem('id', userid);
                    localStorage.setItem('userType', usertype);
                    navigate('/')

                }
                // Redirect to the appropriate page based on user type
                console.log(token);
                // window.location.link = '/User';
                // <Link to={`${window.location.href = '/User'}`} />
            })
            .catch(err => console.error(err));
    };

    return (
        <>
            <Header />

            <div className='LoginStyle'>
                <br />
                <br />
                <MDBContainer >
                    <Form onSubmit={handleLogin}>
                        <MDBRow>


                            <MDBCol sm='6' >
                                <div className='formStyle'>

                                    <div className='d-flex justify-content-center me-5 flex-row ps-5 pt-5 '>
                                        <img src={logo} style={{ width: "150px" }} />

                                    </div>

                                    <div className='d-flex flex-column justify-content-center h-custom-2 w-75 pt-4 '>

                                        <h3 className="fw-normal mb-3 ps-5 pb-3" style={{ letterSpacing: '1px' }}>Log in</h3>

                                        <MDBInput value={email} onChange={(e) => setEmail(e.target.value)} wrapperClass='mb-4 mx-5 w-100' label='Email address' id='formControlLg' type='email' size="lg" />
                                        <MDBInput value={password} onChange={(e) => setPassword(e.target.value)} wrapperClass='mb-4 mx-5 w-100' label='Password' id='formControlLg' type='password' size="lg" />

                                        <MDBBtn type="submit" className="mb-4 px-5 mx-5 w-100" color='info' size='lg'>Login</MDBBtn>

                                        <p className="ms-5" style={{ color: "red" }}>{error}</p>

                                        <p className='ms-5'>Don't have an account? <a href="#!" class="link-info">Register here</a></p>

                                        <br />
                                        <br />

                                    </div>
                                </div>

                            </MDBCol>


                            <MDBCol sm='6' className='d-none d-sm-block px-0'>
                                <img src="https://media.tenor.com/p0G_bmA2vSYAAAAd/login.gif"
                                    alt="Login image" className='rounded ms-5' fluid style={{ width: '500px', height: "600px", objectFit: "fill", objectPosition: 'left' }} />
                            </MDBCol>

                        </MDBRow>
                    </Form>
                </MDBContainer>
                <br />
                <br />
            </div>

        </>
    );
}

export default Login