import React from 'react'
import Footer from "./Footer"
import Header from "./Header"

function User() {
    return (
        <div>
            <Header />
            <Footer />
        </div>
    )
}

export default User