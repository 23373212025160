import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ProductForms from "../Forms/ProductForms"

function ProductModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="mt-5 d-grid">
        <Button variant="light" onClick={handleShow}>
          Add Items
        </Button>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Products</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProductForms />

        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProductModal;
