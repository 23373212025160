import React, { useState, useEffect } from 'react';
import { BsFillTrashFill } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap"

function DeleteIcon(productId) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [data, setData] = useState('');

    const id = productId.productId;

    const deleteData = async () => {
        const response = await fetch(`https://team04mapi.webpark.tech/api/products/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: null
        });

        const data = await response.json();
        console.log(data);
        // You can handle the response data here and update your UI accordingly
    };

    return (
        <>
            <BsFillTrashFill onClick={handleShow} />

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you want to delete product?</p>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-end gap-2'>
                    <Button variant="danger" onClick={handleClose}>Cancel</Button>
                    <Button variant="success" onClick={() => {
                        deleteData(); // Call deleteData function here when the Confirm button is clicked
                        handleClose(); // Close the modal after clicking Confirm
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default DeleteIcon;
