import "bootstrap/dist/css/bootstrap.css";
import "./Styles/Appstyle/App.css";
import Header from "./Components/Header.js";
import HomeRoutes from "./Components/HomeRoutes";
import Footer from "./Components/Footer";

function App() {
  return (
    <div>
      {/* <Header /> */}
      <HomeRoutes />
      <Footer />
    </div>
  );
}

export default App;
