import React from "react";
import ProductsCard from "./ProductsCard";
import Header from "./Header"
import ProductsNav from "./ProductsNav";
import { Col, Container, Row } from "react-bootstrap";
import "../Styles/Homepagestyle/products.css"
import { Fade } from "react-awesome-reveal"
import PaginationProduct from "./PaginationProduct"

function Products() {

  return (
    <div>
      <Header />
      <div className="productStylePage">
        <Container >
          <Row className="md-5">
            <Col>
              {/* <ProductsNav /> */}
            </Col>
          </Row>
          <Row>
            <Fade delay={"60"}>
              <br />
              <br />
              <br />
              <ProductsCard />
            </Fade>
          </Row>
          <br />
          <br />
          <br />
          <br />
          <div className="d-flex justify-content-center">

            {/* <PaginationProduct /> */}
          </div>
          <br />
          <br />
        </Container>
      </div>
    </div>
  );
}

export default Products;
