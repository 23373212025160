import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ProductModal from "../Modals/ProductModal"
import "../../Style/Admin.css";
import AdminNavbar from "../../AdminNavbar";
import ProductsTable from "../Tables/ProductsTable"

const Admin2 = () => {
  return (
    <>
      <AdminNavbar />
      <Container fluid>
        <Row>
          <Col id="sidebar" lg={3}>
            <br />
            <br />
            <br />
            <ProductModal />
          </Col>
          <Col lg={9}>
            <Container className="d-flex  align-items-center">
              <Col className="mb-5 mt-5" style={{ marginLeft: "150px" }}>
                <div className="tableBorder">
                  <ProductsTable fluid />
                </div>
              </Col>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Admin2;
