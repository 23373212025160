import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import Header from "./Header";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom"

export default function ProductCards() {
  const [cartItems, setCartItems] = useState([]); // Array of objects containing product ID and quantity
  const [productsData, setProductsData] = useState([]);

  useEffect(() => {
    const customer = localStorage.getItem("id");
    if (customer === "undefined" || customer === "" || customer === "null" || !customer) {
      try {
        const guestCart = JSON.parse(localStorage.getItem("cartguest"));
        if (guestCart) {
          // Filter out duplicate product IDs and set initial quantity to 1
          const uniqueCartItems = guestCart.reduce((acc, item) => {
            const existingItem = acc.find((cartItem) => cartItem.product_id === item.product_id);
            if (existingItem) {
              existingItem.quantity += 1;
            } else {
              acc.push({ product_id: item.product_id, quantity: 1 });
            }
            return acc;
          }, []);
          setCartItems(uniqueCartItems);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      fetch(`https://team04mapi.webpark.tech/api/cartusers/${customer}`, {
        method: "GET",
        mode: "cors",
      })
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          // Filter out duplicate product IDs and set initial quantity to 1
          const uniqueCartItems = result.data.reduce((acc, item) => {
            const existingItem = acc.find((cartItem) => cartItem.product_id === item.product_id);
            if (existingItem) {
              existingItem.quantity += 1;
            } else {
              acc.push({ product_id: item.product_id, quantity: 1 });
            }
            return acc;
          }, []);
          setCartItems(uniqueCartItems);
        })
        .catch((error) => console.error(error));
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(cartItems) && cartItems.length > 0) {
      const fetchProductData = async () => {
        try {
          const productDataArray = await Promise.all(cartItems.map(({ product_id }) =>
            fetch(`https://team04mapi.webpark.tech/api/products/${product_id}`, {
              method: "GET",
              mode: "cors",
            }).then(response => response.json())
          ));

          setProductsData(productDataArray);
        } catch (error) {
          console.error(error);
        }
      };

      fetchProductData();
    }
  }, [cartItems]);

  // Calculate the total price based on the quantity of each product
  const calculateTotalPrice = () => {
    let total = 0;
    productsData.forEach(({ success, data: { id, price } }) => {
      const cartItem = cartItems.find((item) => item.product_id === id);
      if (cartItem) {
        total += cartItem.quantity * price;
      }
    });
    return total;
  };

  // Function to handle product deletion
  const handleProductDelete = (productId) => {
    setCartItems((prevCartItems) => prevCartItems.filter((item) => item.product_id !== productId));
  };

  return (
    <>
      <Header />
      <section className="h-100" style={{ backgroundColor: "#eee" }}>
        <MDBContainer className="py-5 h-100">
          <MDBRow className="justify-content-center align-items-center h-100">
            <MDBCol md="10">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <MDBTypography tag="h3" className="fw-normal mb-0 text-black">
                  Shopping Cart
                </MDBTypography>
                <div>
                  <p className="mb-0">
                    <span className="text-muted">Sort by:</span>
                    <a href="#!" className="text-body">
                      price <i className="fas fa-angle-down mt-1"></i>
                    </a>
                  </p>
                </div>
              </div>

              {productsData.map(({ success, data: { avatar, id, price, productName } }) => {
                const cartItem = cartItems.find((item) => item.product_id === id);
                if (cartItem) {
                  return (
                    <MDBCard className="rounded-3 mb-4" key={id}>
                      <MDBCardBody className="p-4">
                        <MDBRow className="justify-content-between align-items-center">
                          <MDBCol md="2" lg="2" xl="2">
                            <MDBCardImage
                              className="rounded-3"
                              fluid
                              src={`https://team04mapi.webpark.tech/Images/${avatar}`}
                              alt={avatar}
                            />
                          </MDBCol>
                          <MDBCol md="3" lg="3" xl="3">
                            <p className="lead fw-normal mb-2">{productName}</p>
                          </MDBCol>
                          <MDBCol
                            md="3"
                            lg="3"
                            xl="2"
                            className="d-flex align-items-center justify-content-around"
                          >
                            <MDBBtn color="link" className="px-2">
                              <MDBIcon fas icon="minus" />
                            </MDBBtn>
                            <MDBInput min={0} defaultValue={cartItem.quantity} type="number" size="sm" />
                            <MDBBtn color="link" className="px-2">
                              <MDBIcon fas icon="plus" />
                            </MDBBtn>
                          </MDBCol>
                          <MDBCol md="3" lg="2" xl="2" className="offset-lg-1">
                            <MDBTypography tag="h5" className="mb-0">
                              {price}
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol md="1" lg="1" xl="1" className="text-end">
                            {/* Handler to remove the product from the cart */}
                            <a href="#!" className="text-danger" onClick={() => handleProductDelete(id)}>
                              <MDBIcon fas icon="trash text-danger" size="lg" />
                            </a>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  );
                }
                return null;
              })}

              {/* Display the total price */}
              <div className="text-end mt-3">
                <h5>Total Price: {calculateTotalPrice()}</h5>
              </div>
              <div className="text-end mt-3">
                <NavLink to={'/Checkout'}>
                  <Button variant="success">Checkout</Button>
                </NavLink>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </>
  );
}
