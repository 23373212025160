import React from "react";
import { MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardFooter, MDBRow, MDBCol, MDBContainer, MDBBtn } from "mdb-react-ui-kit";
import "../Styles/Homepagestyle/ProductsandServices.css";
import { Fade } from "react-awesome-reveal";
import motherboard from "../Images/motherboard10.jpg"
import videocard from "../Images/videocard1.jpg"
import systemcooling from "../Images/systemcooling1.jpg"
import ram from "../Images/ram8.jpg"


export default function App() {
  return (
    <div className="PageStyle">
      <MDBContainer>
        <MDBRow>
          <br />
          <br />
          <div className="d-flex justify-content-center mb-5 mt-5">
            <h1 style={{ textAlign: "center", color: "white" }}>Top Selling Computer Components</h1>
          </div>
        </MDBRow>
        <MDBContainer>
          <MDBRow className="row-cols-1 row-cols-md-4 g-5">
            <MDBCol>
              <Fade >
                <MDBCard alignment="center" className="h-80">
                  <MDBCardImage src={motherboard} alt="..." position="top" />
                  <MDBCardBody>
                    <MDBCardTitle className="text-truncate">MSI MAG B550 TOMAHAWK</MDBCardTitle>
                  </MDBCardBody>
                  <MDBCardBody>
                    <p >
                      The MSI MAG B550 TOMAHAWK motherboard is a feature-packed choice for AMD Ryzen processors. With its robust VRM design, PCIe</p>
                  </MDBCardBody>
                </MDBCard>
              </Fade>
            </MDBCol>
            <MDBCol>
              <Fade >
                <MDBCard alignment="center" className="h-80">
                  <MDBCardImage src={videocard} alt="..." position="top" />
                  <MDBCardBody>
                    <MDBCardTitle>NVIDIA GeForce RTX 3080</MDBCardTitle>
                  </MDBCardBody>
                  <MDBCardBody>
                    <p >
                      The NVIDIA GeForce RTX 3080 is a high-end graphics card built for exceptional gaming and rendering performance.
                    </p>
                  </MDBCardBody>
                </MDBCard>
              </Fade>
            </MDBCol>
            <MDBCol>
              <Fade >
                <MDBCard alignment="center" className="h-80">
                  <MDBCardImage src={systemcooling} alt="..." position="top" />
                  <MDBCardBody>
                    <MDBCardTitle>UltraCool Turbo Fan</MDBCardTitle>
                  </MDBCardBody>
                  <MDBCardBody>
                    <p >
                      The UltraCool Turbo Fan is a high-performance cooling fan designed to keep your system running at optimal temperatures.
                    </p>
                  </MDBCardBody>
                </MDBCard>
              </Fade>
            </MDBCol>

            <MDBCol>
              <Fade >
                <MDBCard alignment="center" className="h-80">
                  <MDBCardImage src={ram} alt="..." position="top" />
                  <MDBCardBody>
                    <MDBCardTitle>Kingston HyperX Predator</MDBCardTitle>
                  </MDBCardBody>
                  <MDBCardBody>
                    <p >
                      Kingston HyperX Predator RAM is engineered for extreme performance. With speeds up to 4800MHz and aggressive heat spreaders.
                    </p>
                  </MDBCardBody>
                </MDBCard>
              </Fade>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBContainer>
      <br />
      <br />
    </div>
  );
}
